<template>
    <div class="container-fluid view settings">
        <div class="sidebar-view">
            <component :is="currentComponent"></component>
        </div>
        <div class="content-view">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "Settings",
    computed: {
        currentComponent() {
            return this.$store.getters.user_type + 'SettingsPanel'
        }
    },
    components: {
        ClientSettingsPanel: defineAsyncComponent(() =>
            import("@/views/settings/ClientSettingsPanel")
        ),
        ManagementSettingsPanel: defineAsyncComponent(() =>
            import("@/views/settings/ManagementSettingsPanel")
        ),
        SuperAdminSettingsPanel: defineAsyncComponent(() =>
            import("@/views/settings/SuperAdminSettingsPanel")
        ),
    }
};
</script>